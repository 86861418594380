@import "../node_modules/bootstrap/scss/bootstrap";
@import "fontawesome/font-awesome";
@import "../node_modules/fancybox/dist/scss/jquery.fancybox";

a, a:hover {
  text-decoration: none;
}

.navbar-light .navbar-nav .open > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.open,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  text-decoration: underline;
  color: #FFF;
}

.navbar-light .navbar-nav .nav-link{
  color: #FFF;
}

.main {
  margin-top: 50px;
}

.ligne-tarif > div {
  min-height: 60px;
  display: inline-flex;
  align-items: center;
}

.carousel-item {
  align-items: center;
}

.img-container {
  img {
    margin-bottom: 10px;
  }
}

.autres {
  margin-top: 80px;
}

.autres-boite {
  margin-top: 40px;
}

.contact-zone {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .fa {
    margin-right: 5px;
  }

  a {
    text-decoration: none;
    color: #1c8bea;
    color: #FFF;

    &:hover,
    &:focus{
      //color:#2172b7;
      //color: #d9d9d9;
      text-decoration: underline;
    }
  }
}

.infos {
  margin-top: 80px;
}

.col,
.container-fluid{
  padding: 0;
}

.bullet-points{
  margin: 30px 10px;
}

.copyright{
  p{
    margin: 0;
    padding: 0;
    color: #FFF;
  }

  margin-top: 25px;
  padding: 15px;
  background: #000;
  background-color: #014c8c;
}

nav.navbar{
  background-color: #000;
  background-color: #014c8c;
}

.contact-zone,
.navbar-light .navbar-brand, .navbar-light .navbar-toggler{
  color: #FFF;
}

.navbar-light .navbar-toggler-icon {
  background: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/></svg>");
}